import { Nullable } from '../type/types'

export default class DescriptionMixin
{
    private description: Nullable<string>;

    setDescription(description: Nullable<string>): void
    {
        this.description = description;
    }

    getDescription(): Nullable<string>
    {
        return this.description;
    }
}